import {
  Box,
  Button,
  Divider,
  Heading,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import ConnectWalletModal from 'components/ConnectWalletModal'
import React from 'react'
import { ChainType } from 'types/chain'

interface ConnectWalletPlaceholderProps {
  defaultChainType: ChainType
  description: string
  title: string
}

const ConnectWalletPlaceholder = ({
  defaultChainType,
  description,
  title
}: ConnectWalletPlaceholderProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleConnectWallet = () => {
    onOpen()
  }

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <ConnectWalletModal
        isOpen={isOpen}
        onClose={onClose}
        defaultChainType={defaultChainType}
      />

      <Box p={{ base: 4, md: 6 }}>
        <Heading size="sm">{title}</Heading>
      </Box>

      <Divider />

      <VStack pt={8} w="full" spacing={4}>
        <Text textAlign="center">{description}</Text>
        <Button
          variant="boxShadowFlat"
          onClick={handleConnectWallet}
          _dark={{ bg: 'bgSecondary' }}
        >
          Connect Wallet
        </Button>
      </VStack>
    </Box>
  )
}

export default ConnectWalletPlaceholder
