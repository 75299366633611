import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Collapse,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import { DEFAULT_ASK_PRICES } from 'constants/curves'
import {
  VestingArgs,
  VestingType
} from 'hooks/tokenmill/useCreateMarketAndToken'
import useTokenBalance from 'hooks/useTokenBalance'
import React, { useState } from 'react'
import { Chain } from 'types/dexbarn'
import { Token } from 'types/token'
import { calculateSupplyFromQuoteAmount } from 'utils/bondingCurves'
import { formattedNum } from 'utils/format'
import { zeroAddress } from 'viem'

import { VestingArgsError } from './index'

interface InitialPurchaseInputProps {
  baseTokenSymbol: string
  chain: Chain
  quoteToken: Token
  setVestingArgs: React.Dispatch<React.SetStateAction<VestingArgs | undefined>>
  setVestingArgsError: React.Dispatch<React.SetStateAction<VestingArgsError>>
  totalSupply: number
  vestingArgs: VestingArgs | undefined
}

const InitialPurchaseInput: React.FC<InitialPurchaseInputProps> = ({
  baseTokenSymbol,
  chain,
  quoteToken,
  setVestingArgs,
  setVestingArgsError,
  totalSupply,
  vestingArgs
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [buyAmount, setBuyAmount] = useState<string>('')
  const [vestingType, setVestingType] = useState<VestingType>('1week')
  const [hasSufficientBalance, setHasSufficientBalance] =
    useState<boolean>(true)

  const { data: userQuoteTokenBalance } = useTokenBalance({
    chain,
    token: quoteToken.address !== zeroAddress ? quoteToken.address : 'native'
  })

  const userQuoteTokenBalanceNum =
    userQuoteTokenBalance !== undefined
      ? Number(userQuoteTokenBalance.formatted)
      : undefined

  const onBuyAmountChange = (value: string) => {
    const valueNum = parseFloat(value)
    setBuyAmount(value)

    const hasSufficientBalance =
      value && userQuoteTokenBalanceNum !== undefined
        ? Number(value) <= userQuoteTokenBalanceNum
        : true

    const baseAmountNum = calculateSupplyFromQuoteAmount(
      valueNum,
      DEFAULT_ASK_PRICES[chain]
    )

    setVestingArgs(
      value
        ? {
            baseAmount: baseAmountNum,
            quoteAmount: valueNum,
            vestingType
          }
        : undefined
    )
    setHasSufficientBalance(hasSufficientBalance)
    setVestingArgsError(
      !hasSufficientBalance
        ? 'insufficient_balance'
        : valueNum > 0
          ? 'none'
          : 'no_amount'
    )
  }

  const onVestingTypeChange = (type: VestingType) => {
    setVestingType(type)

    setVestingArgs((args) => {
      if (!args) {
        return undefined
      }

      return {
        ...args,
        vestingType: type
      }
    })
  }

  const handleReset = () => {
    setBuyAmount('')
    setVestingType('1week')
    setHasSufficientBalance(true)
    setVestingArgs(undefined)
    setVestingArgsError('none')
  }

  return (
    <VStack p={{ base: 4, md: 6 }} spacing={4} align="stretch">
      <HStack spacing={4}>
        <Heading size="md">Initial Purchase</Heading>
        <Switch
          isChecked={isOpen}
          onChange={(e) => {
            const isChecked = e.target.checked
            setIsOpen(isChecked)
            handleReset()
          }}
        />
      </HStack>
      <Collapse in={isOpen}>
        <VStack align="stretch" p="1px" spacing={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
            <VStack align="flex-start">
              <Text fontSize="sm">Buy Amount</Text>
              <InputGroup>
                <NumericalInput
                  inputMode="decimal"
                  placeholder="0.0"
                  value={buyAmount}
                  onValueChange={onBuyAmountChange}
                  isInvalid={!hasSufficientBalance}
                />
                <InputRightElement w="fit-content" pr={2}>
                  {quoteToken.symbol}
                </InputRightElement>
              </InputGroup>
              {!hasSufficientBalance && (
                <Text fontSize="sm" color="red.500">
                  Insufficient balance
                </Text>
              )}
            </VStack>

            <VStack align="flex-start">
              <Text fontSize="sm">Vesting</Text>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  w="full"
                  textAlign="left"
                  variant="boxShadowFlat"
                  bg="bgSecondary"
                  px={4}
                  fontSize="md"
                  fontWeight="normal"
                >
                  {vestingType === '1hour' && '1 hour'}
                  {vestingType === '1day' && '1 day'}
                  {vestingType === '3days' && '3 days'}
                  {vestingType === '1week' && '1 week'}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => onVestingTypeChange('1hour')}>
                    1 hour
                  </MenuItem>
                  <MenuItem onClick={() => onVestingTypeChange('1day')}>
                    1 day
                  </MenuItem>
                  <MenuItem onClick={() => onVestingTypeChange('3days')}>
                    3 days
                  </MenuItem>
                  <MenuItem onClick={() => onVestingTypeChange('1week')}>
                    1 week
                  </MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          </SimpleGrid>

          {vestingArgs ? (
            <Text fontSize="sm">
              You&apos;ll get {formattedNum(vestingArgs.baseAmount)}{' '}
              {baseTokenSymbol || 'tokens'} (
              {((vestingArgs.baseAmount / totalSupply) * 100).toFixed(2)}% of
              total supply).
            </Text>
          ) : null}

          <Text fontSize="sm">
            Automatically make the first buy order, guaranteed. The tokens will
            be vested over time, during which they will be staked and earning
            fees from trading activity.
          </Text>
        </VStack>
      </Collapse>
    </VStack>
  )
}

export default InitialPurchaseInput
