import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { TMMarketQueryParam } from 'types/dexbarn'
import { Market } from 'types/market'
import { SolbarnMarket } from 'types/solbarn'
import {
  convertSolbarnMarketToMarket,
  convertToSolbarnFilterBy
} from 'utils/solbarn'

export interface UseGetSolanaMarketsParams {
  enabled?: boolean
  excludeLowVolumeMarkets?: boolean
  filterBy?: TMMarketQueryParam.FilterBy
  graduated?: boolean
  isDesc?: boolean
  orderBy?: TMMarketQueryParam.OrderBy
  pageSize?: number
}

const useGetMarketsFromSolbarn = ({
  enabled,
  excludeLowVolumeMarkets,
  filterBy,
  graduated,
  isDesc,
  orderBy = 'new',
  pageSize = 20
}: UseGetSolanaMarketsParams = {}) => {
  const fetchMarkets = useSolbarnGet<SolbarnMarket[]>('/v1/markets')
  const fetchGraduatedMarkets = useSolbarnGet<SolbarnMarket[]>(
    '/v1/markets/graduated'
  )

  return useInfiniteQuery<Market[]>({
    enabled,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      if (graduated) {
        const data = await fetchGraduatedMarkets({
          params: {
            is_desc: isDesc,
            order_by: orderBy,
            page_num: pageParam,
            page_size: pageSize
          }
        })
        return data.map(convertSolbarnMarketToMarket)
      } else {
        const data = await fetchMarkets({
          params: {
            exclude_low_volume_markets: excludeLowVolumeMarkets,
            filter_by: filterBy ? convertToSolbarnFilterBy(filterBy) : null,
            is_desc: isDesc,
            order_by: orderBy,
            page_num: pageParam,
            page_size: pageSize
          }
        })
        return data.map(convertSolbarnMarketToMarket)
      }
    },
    queryKey: [
      'GetSolanaMarkets',
      pageSize,
      orderBy,
      filterBy,
      excludeLowVolumeMarkets,
      isDesc,
      graduated
    ]
  })
}

export default useGetMarketsFromSolbarn
