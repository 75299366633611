import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketBadge } from 'types/dexbarn'

interface UseGetMarketBadgesProps {
  chain: Chain
  enabled: boolean
  tokenAddress: string
}

const useGetMarketBadges = ({
  chain,
  enabled,
  tokenAddress
}: UseGetMarketBadgesProps) => {
  const fetchBadges = useSolbarnGet<MarketBadge>(
    `/v1/badges/${chain}/${tokenAddress}`
  )

  return useQuery<MarketBadge>({
    enabled,
    queryFn: () => fetchBadges(),
    queryKey: ['GetMarketBadgesSolana', tokenAddress, chain]
  })
}

export default useGetMarketBadges
