import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { FeaturedMarket } from 'types/market'
import { SolbarnFeaturedMarket } from 'types/solbarn'

import useGetMarketFromSolbarn from './useGetMarketFromSolbarn'

interface UseGetFeaturedMarketProps {
  enabled: boolean
}

const useGetFeaturedMarket = ({ enabled }: UseGetFeaturedMarketProps) => {
  const fetchFeaturedMarket =
    useSolbarnGet<SolbarnFeaturedMarket>(`/v1/markets/featured`)

  const { data: featuredMarketInfo, isLoading: isLoadingFeaturedMarketInfo } =
    useQuery<SolbarnFeaturedMarket>({
      enabled,
      queryFn: fetchFeaturedMarket,
      queryKey: ['GetFeaturedMarketSolana']
    })

  const featuredMarketAddress = featuredMarketInfo?.market_address

  const { data: solbarnMarket, isLoading: isLoadingSolbarnMarket } =
    useGetMarketFromSolbarn({
      enabled: !!featuredMarketAddress,
      filterBy: 'd1',
      marketAddress: featuredMarketAddress || ''
    })

  const featuredMarket = useMemo(() => {
    if (!solbarnMarket) return undefined

    return {
      baseMarketCapUsd: solbarnMarket.base_market_cap_usd,
      baseTokenName: solbarnMarket.base_token.name,
      baseTokenSymbol: solbarnMarket.base_token.symbol,
      iconUrl: solbarnMarket.icon_url,
      marketAddress: solbarnMarket.market_address,
      marketCreatedAt: new Date(solbarnMarket.market_created_at),
      numberAsks: solbarnMarket.number_asks,
      numberBids: solbarnMarket.number_bids,
      quoteLiquidityUsd: solbarnMarket.quote_liquidity_usd,
      volumeUsd: solbarnMarket.volume_usd
    } satisfies FeaturedMarket
  }, [solbarnMarket])

  return {
    data: featuredMarket,
    isLoading: isLoadingFeaturedMarketInfo || isLoadingSolbarnMarket
  }
}

export default useGetFeaturedMarket
