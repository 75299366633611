import {
  Button,
  ButtonProps,
  forwardRef,
  useDisclosure
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useWallet } from '@solana/wallet-adapter-react'
import ConnectWalletModal from 'components/ConnectWalletModal'
import { chains } from 'constants/chains'
import useSwitchChain from 'hooks/evm/useSwitchChain'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { getChainId, getChainType } from 'utils/chains'
import { useAccount } from 'wagmi'

interface Web3ButtonProps extends ButtonProps {
  chain: Chain
}

const Web3Button = forwardRef<Web3ButtonProps, 'button'>(
  ({ chain, ...props }, ref) => {
    const targetChain = chain
    const chainType = getChainType(targetChain)
    const targetChainId = getChainId(targetChain)
    const targetChainName =
      chainType === 'evm'
        ? chains.find((c) => c.id === targetChainId)?.name
        : 'Solana'

    const {
      isOpen: isConnectWalletModalOpen,
      onClose: onConnectWalletModalClose,
      onOpen: onConnectWalletModalOpen
    } = useDisclosure()

    const { chain: walletChainEVM, isConnected: isEvmConnected } = useAccount()
    const { publicKey: solanaPublicKey } = useWallet()
    const { switchChain } = useSwitchChain()

    const isSolanaConnected = !!solanaPublicKey
    const isConnected = chainType === 'evm' ? isEvmConnected : isSolanaConnected

    const isChainUnsupported =
      chainType === 'evm' ? targetChainId !== walletChainEVM?.id : false

    if (isConnected && isChainUnsupported) {
      return (
        <Button
          {...props}
          ref={ref}
          leftIcon={undefined}
          rightIcon={undefined}
          isDisabled={false}
          onClick={() => switchChain({ chainId: targetChainId })}
        >
          {t`Switch to ${targetChainName}`}
        </Button>
      )
    } else if (isConnected) {
      return <Button {...props} ref={ref} />
    } else {
      return (
        <>
          <ConnectWalletModal
            isOpen={isConnectWalletModalOpen}
            onClose={onConnectWalletModalClose}
            defaultChainType={chainType}
          />

          <Button
            {...props}
            ref={ref}
            isDisabled={false}
            onClick={onConnectWalletModalOpen}
          >
            [<Trans>Connect Wallet</Trans>]
          </Button>
        </>
      )
    }
  }
)

export default Web3Button
