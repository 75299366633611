import { Icon, IconProps } from '@chakra-ui/react'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { ReactComponent as Disconnect } from 'assets/disconnect.svg'
import { ReactComponent as Discord } from 'assets/discord.svg'
import { ReactComponent as MySettings } from 'assets/my-settings.svg'
import { ReactComponent as Pause } from 'assets/pause.svg'
import { ReactComponent as Play } from 'assets/play.svg'
import { ReactComponent as Refresh } from 'assets/refresh.svg'
import { ReactComponent as Settings } from 'assets/settings.svg'
import { ReactComponent as Telegram } from 'assets/telegram.svg'
import { ReactComponent as Warning } from 'assets/warning.svg'
import { ReactComponent as Website } from 'assets/website.svg'
import { ReactComponent as Twitter } from 'assets/x.svg'
import { ReactComponent as XCircle } from 'assets/x-circle.svg'
import React from 'react'

const TwitterIcon = (props: IconProps) => (
  <Icon as={Twitter} fill="textPrimary" {...props} />
)

const DiscordIcon = (props: IconProps) => (
  <Icon as={Discord} fill="textPrimary" {...props} />
)

const TelegramIcon = (props: IconProps) => (
  <Icon as={Telegram} fill="textPrimary" {...props} />
)

const WebsiteIcon = (props: IconProps) => (
  <Icon as={Website} fill="textPrimary" {...props} />
)

const ArrowLeftIcon = (props: IconProps) => (
  <Icon as={ArrowLeft} fill="textPrimary" {...props} />
)

const DisconnectIcon = (props: IconProps) => (
  <Icon as={Disconnect} fill="red.500" {...props} />
)

const MySettingsIcon = (props: IconProps) => (
  <Icon as={MySettings} fill="textPrimary" {...props} />
)

const RefreshIcon = (props: IconProps) => (
  <Icon as={Refresh} fill="textPrimary" {...props} />
)

const SettingsIcon = (props: IconProps) => (
  <Icon as={Settings} fill="textPrimary" {...props} />
)

const WarningIcon = (props: IconProps) => (
  <Icon as={Warning} fill="textPrimary" {...props} />
)

const PlayIcon = (props: IconProps) => (
  <Icon as={Play} fill="textPrimary" {...props} />
)

const PauseIcon = (props: IconProps) => (
  <Icon as={Pause} fill="textPrimary" {...props} />
)

const XCircleIcon = (props: IconProps) => (
  <Icon as={XCircle} fill="textPrimary" {...props} />
)

export {
  ArrowLeftIcon,
  DisconnectIcon,
  DiscordIcon,
  MySettingsIcon,
  PauseIcon,
  PlayIcon,
  RefreshIcon,
  SettingsIcon,
  TelegramIcon,
  TwitterIcon,
  WarningIcon,
  WebsiteIcon,
  XCircleIcon
}
