import { t } from '@lingui/macro'
import { TokenMillFactoryAbi } from 'constants/abi/tokenMillFactory'
import { TM_FACTORY_ADDRESS } from 'constants/addresses'
import { WNATIVE } from 'constants/token'
import useGetReferrerAddressOnChain from 'hooks/referrals/useGetReferrerAddress'
import { CreateMarketAndTokenArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import useTransactionToast from 'hooks/useTransactionToast'
import { getChainId } from 'utils/chains'
import {
  getAddress,
  Hex,
  isAddress,
  parseEventLogs,
  parseUnits,
  zeroAddress
} from 'viem'
import { useAccount, usePublicClient, useWriteContract } from 'wagmi'

enum TokenType {
  Invalid = 0,
  BasicERC20 = 1
}

const useCreateMarketAndTokenEVM = ({
  askPrices,
  bidPrices,
  chain,
  creatorFeeShareBps,
  decimals,
  enabled = true,
  name,
  stakingFeeShareBps,
  symbol,
  totalSupply,
  vestingArgs
}: CreateMarketAndTokenArgs) => {
  const chainId = getChainId(chain)
  const client = usePublicClient({ chainId })
  const { address: account } = useAccount()
  const addTransactionToast = useTransactionToast()

  const quoteToken = isAddress(WNATIVE[chainId].address)
    ? getAddress(WNATIVE[chainId].address)
    : undefined

  const { data: referrerAddress } = useGetReferrerAddressOnChain({
    chain,
    enabled
  })
  const isSelfReferral =
    referrerAddress?.toLowerCase() === account?.toLowerCase()

  const {
    error: writeContractError,
    isPending,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Created ${symbol} market and token`
        addTransactionToast({
          chain,
          description: transactionSummary,
          hash,
          walletAddress: account || ''
        })
      }
    }
  })

  const createMarketAndTokenAsync = client
    ? async () => {
        const factoryAddress = TM_FACTORY_ADDRESS[chainId]
        if (!factoryAddress) {
          throw new Error('No factory address found')
        }

        if (!quoteToken) {
          throw new Error('No quote token found')
        }

        if (!account) {
          throw new Error('No account found')
        }

        const createMarketArgs = {
          args: ('0x' + decimals.toString(16).padStart(64, '0')) as Hex,
          askPrices: askPrices.map((price) =>
            parseUnits(price.toFixed(decimals), decimals)
          ),
          bidPrices: bidPrices.map((price) =>
            parseUnits(price.toFixed(decimals), decimals)
          ),
          creatorShare: creatorFeeShareBps,
          name,
          quoteToken,
          stakingShare: stakingFeeShareBps,
          symbol,
          tokenType: BigInt(TokenType.BasicERC20),
          totalSupply: BigInt(totalSupply) * BigInt(10) ** BigInt(decimals)
        } as const

        let hash: Hex
        if (vestingArgs) {
          let endDuration: bigint
          switch (vestingArgs?.vestingType) {
            case '1hour':
              endDuration = BigInt(60 * 60)
              break
            case '1day':
              endDuration = BigInt(24 * 60 * 60)
              break
            case '3days':
              endDuration = BigInt(3 * 24 * 60 * 60)
              break
            case '1week':
              endDuration = BigInt(7 * 24 * 60 * 60)
              break
          }

          const createVestingArgs = {
            beneficiary: account,
            cliffDuration: BigInt(0),
            endDuration,
            percent: BigInt(10000),
            start: BigInt(Math.floor(Date.now() / 1000))
          } as const

          const quoteAmount = parseUnits(vestingArgs.quoteAmount.toString(), 18)

          hash = await writeContractAsync({
            abi: TokenMillFactoryAbi,
            address: factoryAddress,
            args: [
              createMarketArgs,
              [createVestingArgs],
              referrerAddress && !isSelfReferral
                ? getAddress(referrerAddress)
                : zeroAddress,
              quoteAmount,
              BigInt(0)
            ],
            functionName: 'createMarketAndVestings',
            value: quoteAmount
          })
        } else {
          hash = await writeContractAsync({
            abi: TokenMillFactoryAbi,
            address: factoryAddress,
            args: [createMarketArgs],
            functionName: 'createMarketAndToken'
          })
        }

        const receipt = await client.waitForTransactionReceipt({ hash })
        const logs = parseEventLogs({
          abi: TokenMillFactoryAbi,
          eventName: 'MarketCreated',
          logs: receipt.logs
        })

        return {
          baseTokenAddress: logs[0].args.baseToken,
          hash,
          marketAddress: logs[0].args.market
        }
      }
    : undefined

  return {
    createMarketAndTokenAsync,
    error: writeContractError,
    isLoading: isPending
  }
}

export default useCreateMarketAndTokenEVM
