import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Skeleton,
  SkeletonText,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import ExpandableText from 'components/ExpandableText'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { formatNumberNoDecimalsToK, formattedNum } from 'utils/format'

import TokenBadges from './TokenBadges'
import TokenEditModal from './TokenEditModal'

interface TokenInfoSectionProps {
  chain: Chain
  isCreator: boolean
  market: ReturnType<typeof useGetMarket>['data']
}

const TokenInfoSection = ({
  chain,
  isCreator,
  market
}: TokenInfoSectionProps) => {
  const {
    isOpen: isEditModalOpen,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen
  } = useDisclosure()

  const token = market?.baseToken
  const twitterUrl = market?.twitterUrl
  const discordUrl = market?.discordUrl
  const telegramUrl = market?.telegramUrl
  const websiteUrl = market?.websiteUrl

  const hasSocialUrls =
    !!twitterUrl || !!discordUrl || !!telegramUrl || !!websiteUrl

  return (
    <Box w="full" p={{ base: 0, md: 6 }}>
      <HStack align="flex-start">
        <Skeleton
          h="80px"
          w={!!market ? 'auto' : '80px'}
          isLoaded={!!market}
          flexShrink={0}
        >
          <Image src={market?.baseTokenLogoUrl} h="80px" objectFit="contain" />
        </Skeleton>

        <VStack align="flex-start" w="full">
          <VStack spacing={0} align="flex-start" w="full">
            <HStack w="full" justify="space-between">
              <Skeleton isLoaded={!!market}>
                <HStack flexWrap="wrap" spacing={0} columnGap={2} fontSize="sm">
                  <Text fontWeight="bold">{token?.symbol || 'symbol'}</Text>
                  <Text textColor="textSecondary">{token?.name || 'name'}</Text>
                </HStack>
              </Skeleton>

              {!!market && isCreator ? (
                <>
                  <TokenEditModal
                    isOpen={isEditModalOpen}
                    onClose={onEditModalClose}
                    market={market}
                    chain={chain}
                  />
                  <Button
                    size={{ base: 'xs', md: 'sm' }}
                    variant="boxShadowFlat"
                    _dark={{ bg: 'bgSecondary' }}
                    alignSelf="flex-end"
                    onClick={onEditModalOpen}
                  >
                    [Edit]
                  </Button>
                </>
              ) : null}
            </HStack>

            {hasSocialUrls && (
              <Flex
                columnGap={2}
                fontSize="sm"
                textColor="textSecondary"
                flexWrap="wrap"
              >
                {twitterUrl && (
                  <Link href={twitterUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Twitter]
                    </Button>
                  </Link>
                )}

                {discordUrl && (
                  <Link href={discordUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Discord]
                    </Button>
                  </Link>
                )}

                {telegramUrl && (
                  <Link href={telegramUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Telegram]
                    </Button>
                  </Link>
                )}

                {websiteUrl && (
                  <Link href={websiteUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Website]
                    </Button>
                  </Link>
                )}
              </Flex>
            )}
          </VStack>

          <SkeletonText noOfLines={2} isLoaded={!!market}>
            <ExpandableText
              text={market?.description || '[no description]'}
              fontSize={{ base: 'xs', md: 'sm' }}
              numberOfCharactersWhenCollapsed={70}
              display="inline-block"
              overflowWrap="break-word"
              wordBreak="break-word"
            />
          </SkeletonText>
        </VStack>
      </HStack>

      {market ? (
        <Box mt={{ base: 4, md: 6 }}>
          <TokenBadges chain={chain} tokenAddress={market.baseToken.address} />
        </Box>
      ) : null}

      {market ? (
        <Box
          mt={{ base: 4, md: 6 }}
          display={{ base: 'grid', md: 'flex' }}
          gridTemplateColumns="1fr 1fr"
          gap={{ base: 2, md: 4 }}
          flexWrap="wrap"
        >
          <TouchFriendlyTooltip
            label={`The price in ${market.quoteCurrency.symbol} at which you can buy tokens`}
          >
            <HStack>
              <Text
                textColor="textSecondary"
                fontSize="sm"
                textDecor="underline"
                textDecorationStyle="dotted"
              >
                Buy
              </Text>
              <Text textColor="green.400" fontSize="sm" fontWeight="bold">
                {formattedNum(market.askPrice, { allowSmallDecimals: true })}
              </Text>
            </HStack>
          </TouchFriendlyTooltip>

          <TouchFriendlyTooltip
            label={`The price in ${market.quoteCurrency.symbol} at which you can sell tokens`}
          >
            <HStack>
              <Text
                textColor="textSecondary"
                fontSize="sm"
                textDecor="underline"
                textDecorationStyle="dotted"
              >
                Sell
              </Text>
              <Text textColor="red.500" fontSize="sm" fontWeight="bold">
                {formattedNum(market.bidPrice, { allowSmallDecimals: true })}
              </Text>
            </HStack>
          </TouchFriendlyTooltip>

          <TouchFriendlyTooltip
            label={`${market.fees.stakingFeeShare / 100}% staking • ${
              (8000 - market.fees.stakingFeeShare) / 100
            }% creator • 20% protocol`}
          >
            <HStack>
              <Text
                textColor="textSecondary"
                fontSize="sm"
                textDecor="underline"
                textDecorationStyle="dotted"
              >
                Fees
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                {formattedNum((1 - market.bidPrice / market.askPrice) * 100, {
                  places: 2
                })}
                %
              </Text>
            </HStack>
          </TouchFriendlyTooltip>

          <TouchFriendlyTooltip label="Last sale price * Circulating supply">
            <HStack>
              <Text
                textColor="textSecondary"
                fontSize="sm"
                textDecor="underline"
                textDecorationStyle="dotted"
              >
                MCAP
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                {formatNumberNoDecimalsToK(market.marketCapUsd || 0, {
                  usd: true
                })}
              </Text>
            </HStack>
          </TouchFriendlyTooltip>
        </Box>
      ) : null}
    </Box>
  )
}

export default TokenInfoSection
