import { BN, Idl, Program } from '@coral-xyz/anchor'
import { bs58 } from '@coral-xyz/anchor/dist/cjs/utils/bytes'
import { t } from '@lingui/macro'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Keypair, PublicKey } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { IS_TESTNET } from 'constants/chains'
import { CreateMarketAndTokenArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import useTransactionToast from 'hooks/useTransactionToast'
import { METAPLEX_METADATA_PROGRAM, SCALE, TM_CONFIG } from 'solana/constants'
import TM_IDL from 'solana/idl/token_mill.json'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import {
  buildTransactionWithPriorityFee,
  sendJitoBundle
} from 'utils/transaction'
import { getCreateVestingInstructions } from 'utils/vesting'
import { parseUnits } from 'viem'

import { getSwapInstructions } from './useSimulateSwap'

const useCreateTokenMarketSolana = ({
  askPrices,
  bidPrices,
  creatorFeeShareBps,
  name,
  stakingFeeShareBps,
  symbol,
  totalSupply,
  vestingArgs
}: CreateMarketAndTokenArgs) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  return useMutation({
    mutationFn: async ({ logoURI }: { logoURI: string }) => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!totalSupply) throw new Error('Total supply not provided')
      if (creatorFeeShareBps === undefined)
        throw new Error('Creator fee share not provided')
      if (stakingFeeShareBps === undefined)
        throw new Error('Staking fee share not provided')

      // Initialize the program
      const program = new Program(TM_IDL as Idl, {
        connection
      })

      // Generate a new keypair for the base token
      const baseTokenKeypair = Keypair.generate()
      const baseToken = baseTokenKeypair.publicKey

      // Derive the mill address
      const [market] = PublicKey.findProgramAddressSync(
        [Buffer.from('market'), baseToken.toBuffer()],
        program.programId
      )

      // Get the market's base token ATA
      const marketBaseTokenATA = spl.getAssociatedTokenAddressSync(
        baseToken,
        market,
        true,
        spl.TOKEN_PROGRAM_ID
      )

      // Get quote token badge
      const quoteToken = spl.NATIVE_MINT
      const [quoteTokenBadge] = PublicKey.findProgramAddressSync(
        [
          Buffer.from('quote_token_badge'),
          TM_CONFIG.toBuffer(),
          quoteToken.toBuffer()
        ],
        program.programId
      )

      // Get metadata PDA
      const [baseTokenMetadata] = PublicKey.findProgramAddressSync(
        [
          Buffer.from('metadata'),
          METAPLEX_METADATA_PROGRAM.toBuffer(),
          baseToken.toBuffer()
        ],
        METAPLEX_METADATA_PROGRAM
      )

      // create market
      const createMarketIx = await program.methods
        .createMarketWithSpl(
          name,
          symbol,
          logoURI,
          new BN(totalSupply * 1e6),
          creatorFeeShareBps,
          stakingFeeShareBps
        )
        .accounts({
          baseTokenMetadata,
          baseTokenMint: baseToken,
          config: TM_CONFIG,
          creator: wallet.publicKey,
          market,
          marketBaseTokenAta: marketBaseTokenATA,
          quoteTokenBadge,
          quoteTokenMint: quoteToken
        })
        .instruction()

      // Set prices
      const setPricesIx = await program.methods
        .setMarketPrices(
          bidPrices.map((price) => new BN(price * SCALE)),
          askPrices.map((price) => new BN(price * SCALE))
        )
        .accountsPartial({
          creator: wallet.publicKey,
          market
        })
        .instruction()

      // Create market quote token ATA
      const marketQuoteTokenATA = spl.getAssociatedTokenAddressSync(
        quoteToken,
        market,
        true,
        spl.TOKEN_PROGRAM_ID
      )
      const createMarketQuoteTokenATAIx =
        spl.createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          marketQuoteTokenATA,
          market,
          quoteToken,
          spl.TOKEN_PROGRAM_ID
        )

      if (vestingArgs) {
        const instructionsA = [
          createMarketIx,
          setPricesIx,
          createMarketQuoteTokenATAIx
        ]

        const instructionsB = await getSwapInstructions({
          amountIn: parseUnits(vestingArgs.quoteAmount.toString(), 9),
          baseTokenAddress: baseToken.toBase58(),
          connection,
          marketAddress: market.toBase58(),
          minAmountOut: BigInt(0),
          quoteTokenAddress: quoteToken.toBase58(),
          swapType: 'buy',
          walletPublicKey: wallet.publicKey
        })

        const vestingInstructions = await getCreateVestingInstructions({
          baseTokenAddress: baseToken.toBase58(),
          connection,
          marketAddress: market.toBase58(),
          vestingArgs,
          wallet: wallet.publicKey
        })
        const instructionsC = vestingInstructions.instructions
        const vestingPlanKeypair = vestingInstructions.vestingPlanKeypair

        const [txnA, txnB, txnC] = await Promise.all([
          buildTransactionWithPriorityFee(
            connection,
            instructionsA,
            wallet.publicKey,
            maxTransactionFee,
            feeMode,
            { addJitoTip: true }
          ),
          buildTransactionWithPriorityFee(
            connection,
            instructionsB,
            wallet.publicKey,
            maxTransactionFee,
            feeMode,
            { computeUnitLimit: 175_000 }
          ),
          instructionsC.length > 0
            ? buildTransactionWithPriorityFee(
                connection,
                instructionsC,
                wallet.publicKey,
                maxTransactionFee,
                feeMode,
                { computeUnitLimit: 85_000 }
              )
            : null
        ])

        txnA.transaction.sign([baseTokenKeypair])
        if (txnC && vestingPlanKeypair) {
          txnC.transaction.sign([vestingPlanKeypair])
        }

        if (!wallet.signAllTransactions)
          throw new Error("Can't sign all transactions with wallet")

        const transactions = [txnA.transaction, txnB.transaction]
        if (txnC) {
          transactions.push(txnC.transaction)
        }

        const signedTxns = await wallet.signAllTransactions(transactions)
        const signature = bs58.encode(signedTxns[0].signatures[0])

        if (IS_TESTNET) {
          // Jito is not supported on devnet
          const txns = [txnA, txnB]
          if (txnC) {
            txns.push(txnC)
          }

          for (let i = 0; i < signedTxns.length; i++) {
            const signature = await connection.sendRawTransaction(
              signedTxns[i].serialize()
            )

            await connection.confirmTransaction(
              {
                blockhash: txns[i].latestBlockhash.blockhash,
                lastValidBlockHeight:
                  txns[i].latestBlockhash.lastValidBlockHeight,
                signature
              },
              'confirmed'
            )
          }
        } else {
          await sendJitoBundle(signedTxns)
        }

        addTransactionToast({
          chain: 'solana',
          description: t`Created token`,
          hash: signature,
          walletAddress: wallet.publicKey.toBase58()
        })

        const response = await connection.confirmTransaction(
          {
            blockhash: txnA.latestBlockhash.blockhash,
            lastValidBlockHeight: txnA.latestBlockhash.lastValidBlockHeight,
            signature
          },
          'confirmed'
        )

        if (response.value.err) {
          throw new Error(`Create market transaction failed`)
        }

        return {
          baseTokenAddress: baseToken.toBase58(),
          hash: signature,
          marketAddress: market.toBase58()
        }
      } else {
        const instructions = [
          createMarketIx,
          createMarketQuoteTokenATAIx,
          setPricesIx
        ]

        const { latestBlockhash, transaction } =
          await buildTransactionWithPriorityFee(
            connection,
            instructions,
            wallet.publicKey,
            maxTransactionFee,
            feeMode
          )

        transaction.sign([baseTokenKeypair])

        const signature = await wallet.sendTransaction(transaction, connection)

        addTransactionToast({
          chain: 'solana',
          description: t`Created token`,
          hash: signature,
          walletAddress: wallet.publicKey.toBase58()
        })

        const response = await connection.confirmTransaction(
          {
            blockhash: latestBlockhash.blockhash,
            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
            signature
          },
          'confirmed'
        )

        if (response.value.err) {
          throw new Error(`Create market transaction failed`)
        }

        return {
          baseTokenAddress: baseToken.toBase58(),
          hash: signature,
          marketAddress: market.toBase58()
        }
      }
    }
  })
}

export default useCreateTokenMarketSolana
