import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { DexbarnFeaturedMarket } from 'types/dexbarn'
import { Chain } from 'types/dexbarn'
import { FeaturedMarket } from 'types/market'

import useGetMarketFromBarn from './useGetMarketFromBarn'

interface UseGetFeaturedMarketProps {
  chain: Chain
  enabled: boolean
}

const useGetFeaturedMarket = ({
  chain,
  enabled
}: UseGetFeaturedMarketProps) => {
  const fetchFeaturedMarket = useDexbarnGet<DexbarnFeaturedMarket>(
    `/v1/markets/${chain}/featured`
  )

  const { data: featuredMarketInfo, isLoading: isLoadingFeaturedMarketInfo } =
    useQuery({
      enabled,
      queryFn: () => fetchFeaturedMarket(),
      queryKey: ['GetFeaturedMarketEVM', chain]
    })

  const featuredMarketAddress = featuredMarketInfo?.marketAddress

  const { data: dexbarnMarket, isLoading: isLoadingDexbarnMarket } =
    useGetMarketFromBarn({
      chain,
      enabled: !!featuredMarketAddress,
      filterBy: '1d',
      marketAddress: featuredMarketAddress || ''
    })

  const featuredMarket = useMemo(() => {
    if (!dexbarnMarket) return undefined

    return {
      baseMarketCapUsd: dexbarnMarket.baseMarketCapUsd,
      baseTokenName: dexbarnMarket.baseToken.name,
      baseTokenSymbol: dexbarnMarket.baseToken.symbol,
      iconUrl: dexbarnMarket.iconUrl,
      marketAddress: dexbarnMarket.marketAddress,
      marketCreatedAt: new Date(dexbarnMarket.marketCreatedAt),
      numberAsks: dexbarnMarket.numberAsks,
      numberBids: dexbarnMarket.numberBids,
      quoteLiquidityUsd: dexbarnMarket.quoteLiquidityUsd,
      volumeUsd: dexbarnMarket.volumeUsd
    } satisfies FeaturedMarket
  }, [dexbarnMarket])

  return {
    data: featuredMarket,
    isLoading: isLoadingFeaturedMarketInfo || isLoadingDexbarnMarket
  }
}

export default useGetFeaturedMarket
