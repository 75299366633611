import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { SolbarnCrownRushLeaderboardEntry } from 'types/solbarn'

interface UseGetCrownRushLeaderboardProps {
  fromTimestamp: number
  toTimestamp: number
  enabled?: boolean
}

const useGetCrownRushLeaderboard = ({
  enabled = true,
  fromTimestamp,
  toTimestamp
}: UseGetCrownRushLeaderboardProps) => {
  const fetchLeaderboard = useSolbarnGet<SolbarnCrownRushLeaderboardEntry[]>(
    '/v1/markets/featured-leaderboard'
  )

  return useQuery<SolbarnCrownRushLeaderboardEntry[]>({
    enabled,
    queryFn: () =>
      fetchLeaderboard({
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp
        }
      }),
    queryKey: ['GetCrownRushLeaderboard', fromTimestamp, toTimestamp]
  })
}

export default useGetCrownRushLeaderboard
