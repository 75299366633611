import { Chain, TMMarketQueryParam } from 'types/dexbarn'
import { Market } from 'types/market'
import { SolbarnMarket, SolbarnMarketQueryParam } from 'types/solbarn'

import { getResizedImageUrl } from './image'

export const convertToSolbarnFilterBy = (
  filterBy: TMMarketQueryParam.FilterBy
): SolbarnMarketQueryParam.FilterBy => {
  switch (filterBy) {
    case '1h':
      return 'h1'
    case '1d':
      return 'd1'
    case '5m':
      return 'm5'
    case '6h':
      return 'h6'
    case '7d':
      return 'd7'
      break
    case '14d':
      return 'd14'
    case '30d':
      return 'd30'
  }
}

export const convertSolbarnMarketToMarket = (market: SolbarnMarket): Market => {
  return {
    baseToken: {
      address: market.base_token.address,
      decimals: market.base_token.decimals,
      name: market.base_token.name,
      priceNative: market.base_token.price_native.toString(),
      priceUsd: market.base_token.price_usd,
      symbol: market.base_token.symbol
    },
    baseTokenPctChange1d: market.base_token_pct_change_1d,
    baseTokenPctChange1h: market.base_token_pct_change_1h,
    baseTokenPctChange5m: market.base_token_pct_change_5m,
    baseTokenPctChange6h: market.base_token_pct_change_6h,
    chain: 'solana' as Chain,
    circulatingSupply: market.circulating_supply,
    circulatingSupplyUsd: market.base_market_cap_usd,
    createdAt: new Date(market.market_created_at),
    description: market.description,
    graduatedAt: market.graduated_timestamp
      ? new Date(market.graduated_timestamp)
      : undefined,
    liquidityUsd: market.quote_liquidity_usd,
    logoUrl: getResizedImageUrl(market.icon_url) || '',
    marketAddress: market.market_address,
    numberBuys: market.number_bids,
    numberSells: market.number_asks,
    quoteToken: {
      address: market.quote_token.address,
      decimals: market.quote_token.decimals,
      name: market.quote_token.name,
      priceNative: market.quote_token.price_native.toString(),
      priceUsd: market.quote_token.price_usd,
      symbol: market.quote_token.symbol.slice(0, 6)
    },
    stakingApr: market.staking_apr,
    stakingPct: market.staking_pct,
    vestingPct: market.vesting_pct,
    volumeUsd: market.volume_usd
  } satisfies Market
}
