export interface PricePoint {
  price: number
  supply: number
}

export const fillDataWithWhitespace = (
  data: PricePoint[],
  activePrice: number | undefined,
  circulatingSupply: number,
  maxPoints: number = 1000
) => {
  if (data.length === 0 || activePrice === undefined) return data

  let sortedData = [...data].sort((a, b) => a.supply - b.supply)

  // Add active price point at circulatingSupply if it doesn't exist
  const activePriceIndex = sortedData.findIndex(
    (point) => point.price === activePrice
  )
  if (activePriceIndex === -1) {
    sortedData.push({ price: activePrice, supply: circulatingSupply })
    sortedData = sortedData.sort((a, b) => a.supply - b.supply)
  }

  const minSupply = sortedData[0].supply
  const maxSupply = sortedData[sortedData.length - 1].supply
  const supplyRange = maxSupply - minSupply

  // Find the active price point (now guaranteed to exist)
  const activePricePoint = sortedData.find(
    (point) => point.price === activePrice
  )!

  // Calculate the number of points on each side of the active price point
  const pointsBeforeActive = Math.floor(
    ((maxPoints - 3) * (activePricePoint.supply - minSupply)) / supplyRange
  )
  const pointsAfterActive = maxPoints - 3 - pointsBeforeActive

  // Calculate step sizes for before and after the active point
  const stepBefore =
    (activePricePoint.supply - minSupply) / (pointsBeforeActive + 1)
  const stepAfter =
    (maxSupply - activePricePoint.supply) / (pointsAfterActive + 1)

  const filledData: PricePoint[] = []

  // Add the first point
  filledData.push(sortedData[0])

  // Add points before the active price point
  for (let i = 1; i <= pointsBeforeActive; i++) {
    const supply = minSupply + i * stepBefore
    const price = interpolatePrice(sortedData, supply)
    filledData.push({ price, supply })
  }

  // Add the active price point
  if (filledData.findIndex((point) => point.price === activePrice) === -1) {
    filledData.push(activePricePoint)
  }

  // Add points after the active price point
  for (let i = 1; i <= pointsAfterActive; i++) {
    const supply = activePricePoint.supply + i * stepAfter
    const price = interpolatePrice(sortedData, supply)
    filledData.push({ price, supply })
  }

  // Add the last point
  if (filledData[filledData.length - 1].supply !== maxSupply) {
    filledData.push(sortedData[sortedData.length - 1])
  }

  return filledData
}

const interpolatePrice = (data: PricePoint[], supply: number): number => {
  const index = data.findIndex((point) => point.supply > supply)
  if (index === -1) return data[data.length - 1].price
  if (index === 0) return data[0].price

  const before = data[index - 1]
  const after = data[index]
  const ratio = (supply - before.supply) / (after.supply - before.supply)
  return before.price + ratio * (after.price - before.price)
}

export function calculateQuoteAmountToBuySupply(
  supply: number,
  askPricePoints: PricePoint[]
): number {
  if (askPricePoints.length === 0) {
    return 0
  }

  const totalSupply = askPricePoints[askPricePoints.length - 1].supply

  const n = askPricePoints.length - 1
  const w = totalSupply / n
  const m = Math.floor(supply / w)

  let totalQuoteAmount = 0

  // Calculate the integral part
  for (let i = 0; i < m; i++) {
    const avgPrice = (askPricePoints[i + 1].price + askPricePoints[i].price) / 2
    totalQuoteAmount += avgPrice * w
  }

  // Calculate the remaining part
  const r = supply - m * w
  if (r > 0) {
    const p_m = askPricePoints[m].price
    const p_m_plus_1 =
      m + 1 < askPricePoints.length ? askPricePoints[m + 1].price : p_m

    totalQuoteAmount +=
      ((p_m_plus_1 - p_m) / (2 * w)) * Math.pow(r, 2) + p_m * r
  }

  return totalQuoteAmount
}

export function getCurrentPrices(
  askPricePoints: PricePoint[],
  bidPricePoints: PricePoint[],
  circulatingSupply: number
): { askPrice: number; bidPrice: number } {
  if (askPricePoints.length === 0 || bidPricePoints.length === 0) {
    return { askPrice: 0, bidPrice: 0 }
  }

  const askPrice = interpolatePrice(askPricePoints, circulatingSupply)
  const bidPrice = interpolatePrice(bidPricePoints, circulatingSupply)

  return { askPrice, bidPrice }
}

export function calculateSupplyFromQuoteAmount(
  quoteAmount: number,
  askPricePoints: PricePoint[]
): number {
  if (askPricePoints.length === 0 || quoteAmount <= 0) {
    return 0
  }

  const totalSupply = askPricePoints[askPricePoints.length - 1].supply
  const n = askPricePoints.length - 1
  const w = totalSupply / n

  let remainingQuoteAmount = quoteAmount
  let totalSupplyAmount = 0

  // Calculate full segments first
  for (let i = 0; i < n; i++) {
    const p0 = askPricePoints[i].price
    const p1 = askPricePoints[i + 1].price
    const segmentCost = p0 * w + ((p1 - p0) * w * w) / (2 * w)

    if (remainingQuoteAmount <= segmentCost) {
      // Found the segment - solve quadratic equation
      // p0*x + (p1-p0)/(2*w)*x^2 = remainingQuoteAmount
      const a = (p1 - p0) / (2 * w)
      const b = p0
      const c = -remainingQuoteAmount

      const x = (-b + Math.sqrt(b * b - 4 * a * c)) / (2 * a)
      return totalSupplyAmount + x
    }

    remainingQuoteAmount -= segmentCost
    totalSupplyAmount += w
  }

  // If we get here, the quote amount is too large
  return totalSupply
}
