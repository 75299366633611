import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Box,
  Hide,
  HStack,
  LinkBox,
  LinkOverlay,
  Show,
  Spacer,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Market } from 'types/market'
import {
  formatNumberNoDecimalsToK,
  formatShortDistanceToNow
} from 'utils/format'

import PriceChangeColumn from './PriceChangeColumn'

const Stat = ({ label, value }: { label: string; value: string }) => (
  <HStack spacing={2}>
    <Text fontSize="sm" color="textSecondary">
      {label}
    </Text>
    <Text fontSize="sm" fontWeight="medium">
      {value}
    </Text>
  </HStack>
)

const MarketCard = ({ market }: { market: Market }) => {
  return (
    <LinkBox
      bg="bgSecondary"
      p={4}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      transition="all 0.2s"
    >
      <LinkOverlay as={NavLink} to={`/${market.chain}/${market.marketAddress}`}>
        <VStack spacing={4} w="full">
          <HStack spacing={4} w="full">
            <CurrencyLogo
              logoURI={market.logoUrl}
              boxSize={12}
              objectFit="cover"
              chain={market.chain}
            />

            <VStack w="full" align="flex-start">
              <Stack
                spacing={1}
                w="full"
                align="flex-start"
                flexDir={{ base: 'column', lg: 'row' }}
              >
                <HStack fontSize="sm" w={{ base: 'full', lg: 'auto' }}>
                  <Text fontWeight="bold" noOfLines={1}>
                    {market.baseToken.symbol}
                  </Text>
                  <Text color="textSecondary" noOfLines={1}>
                    {market.baseToken.name}
                  </Text>

                  {market.graduatedAt ? (
                    <Box zIndex={1}>
                      <TouchFriendlyTooltip label="Tokens are graduated when their market cap exceeds $50k">
                        <CheckCircleIcon color="yellowBar" />
                      </TouchFriendlyTooltip>
                    </Box>
                  ) : null}

                  <Show below="lg">
                    <Spacer />

                    <Text color="textSecondary" fontSize="sm">
                      {formatShortDistanceToNow(market.createdAt)}
                    </Text>
                  </Show>
                </HStack>

                <Hide below="lg">
                  <Spacer />

                  <HStack spacing={4} fontSize="sm">
                    <HStack>
                      <Text fontSize="sm" color="textSecondary">
                        5M
                      </Text>
                      <PriceChangeColumn value={market.baseTokenPctChange5m} />
                    </HStack>
                    <HStack>
                      <Text fontSize="sm" color="textSecondary">
                        1H
                      </Text>
                      <PriceChangeColumn value={market.baseTokenPctChange1h} />
                    </HStack>
                    <HStack>
                      <Text fontSize="sm" color="textSecondary">
                        6H
                      </Text>
                      <PriceChangeColumn value={market.baseTokenPctChange6h} />
                    </HStack>
                    <HStack>
                      <Text fontSize="sm" color="textSecondary">
                        1D
                      </Text>
                      <PriceChangeColumn value={market.baseTokenPctChange1d} />
                    </HStack>
                  </HStack>
                </Hide>

                <Show below="lg">
                  <HStack spacing={4} fontSize="sm">
                    <HStack>
                      <Text fontSize="sm" color="textSecondary">
                        1H
                      </Text>
                      <PriceChangeColumn value={market.baseTokenPctChange1h} />
                    </HStack>
                    <HStack>
                      <Text fontSize="sm" color="textSecondary">
                        24H
                      </Text>
                      <PriceChangeColumn value={market.baseTokenPctChange1d} />
                    </HStack>
                  </HStack>
                </Show>
              </Stack>

              <Hide below="lg">
                <HStack spacing={3}>
                  <Stat
                    label="MCAP"
                    value={formatNumberNoDecimalsToK(
                      market.circulatingSupplyUsd,
                      {
                        usd: true
                      }
                    )}
                  />
                  <Text textColor="textSecondary">•</Text>
                  <Stat
                    label="LIQ"
                    value={formatNumberNoDecimalsToK(market.liquidityUsd, {
                      usd: true
                    })}
                  />
                  <Text textColor="textSecondary">•</Text>
                  <Stat
                    label="VOL"
                    value={formatNumberNoDecimalsToK(market.volumeUsd, {
                      usd: true
                    })}
                  />
                  <Text textColor="textSecondary">•</Text>
                  <HStack spacing={2}>
                    <Text fontSize="sm" color="textSecondary">
                      TXNS
                    </Text>
                    <HStack justifyContent="flex-end" fontSize="sm">
                      <Text textColor="green.400">{market.numberBuys}</Text>
                      <Text textColor="textSecondary">/</Text>
                      <Text textColor="red.500">{market.numberSells}</Text>
                    </HStack>
                  </HStack>
                  <Text textColor="textSecondary">•</Text>
                  <Text color="textSecondary" fontSize="sm">
                    {formatShortDistanceToNow(market.createdAt)}
                  </Text>
                </HStack>
              </Hide>
            </VStack>
          </HStack>

          <Show below="lg">
            <HStack spacing={2} flexWrap="wrap" w="full">
              <Stat
                label="MCAP"
                value={formatNumberNoDecimalsToK(market.circulatingSupplyUsd, {
                  usd: true
                })}
              />
              <Text textColor="textSecondary">•</Text>
              <Stat
                label="VOL"
                value={formatNumberNoDecimalsToK(market.volumeUsd, {
                  usd: true
                })}
              />
              <Text textColor="textSecondary">•</Text>
              <HStack spacing={2}>
                <Text fontSize="sm" color="textSecondary">
                  TXNS
                </Text>
                <HStack justifyContent="flex-end" fontSize="sm" spacing={0}>
                  <Text textColor="green.400">{market.numberBuys}</Text>
                  <Text textColor="textSecondary">/</Text>
                  <Text textColor="red.500">{market.numberSells}</Text>
                </HStack>
              </HStack>
            </HStack>
          </Show>
        </VStack>
      </LinkOverlay>
    </LinkBox>
  )
}

export default MarketCard
