import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Box,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import CoinsIcon from 'assets/coin.png'
import DollarSignIcon from 'assets/dollar.png'
import ChainIcon from 'components/ChainIcon'
import SortableTableHeader from 'components/SortableTableHeader'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { TMMarketQueryParam } from 'types/dexbarn'
import { Market } from 'types/market'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

import PriceChangeColumn from './PriceChangeColumn'

type PriceDisplay = 'usd' | 'native'

interface MarketsTableProps {
  graduated: boolean
  isSortDescending: boolean
  markets: Market[]
  onHeaderClick: (method: TMMarketQueryParam.OrderBy) => void
  sortMethod: TMMarketQueryParam.OrderBy
}

const MarketsTable = ({
  graduated,
  isSortDescending,
  markets,
  onHeaderClick,
  sortMethod
}: MarketsTableProps) => {
  const [priceDisplay, setPriceDisplay] = useState<PriceDisplay>('usd')

  const togglePriceDisplay = () => {
    setPriceDisplay((prev) => (prev === 'usd' ? 'native' : 'usd'))
  }

  return (
    <TableContainer w="full" minH="70svh">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Token</Th>
            <Th textAlign="right">
              <HStack justifyContent="flex-end" spacing={0}>
                <Text textColor="textSecondary">Price</Text>
                <TouchFriendlyTooltip label="Toggle usd and native price">
                  <IconButton
                    borderRadius="full"
                    bg="transparent"
                    p={0}
                    aria-label="Toggle price currency"
                    icon={
                      priceDisplay === 'usd' ? (
                        <Image
                          opacity={0.5}
                          src={DollarSignIcon}
                          boxSize={4}
                          _dark={{ filter: 'invert(1)' }}
                        />
                      ) : (
                        <Image
                          src={CoinsIcon}
                          opacity={0.5}
                          boxSize={4}
                          _dark={{ filter: 'invert(1)' }}
                        />
                      )
                    }
                    size="xs"
                    onClick={togglePriceDisplay}
                  />
                </TouchFriendlyTooltip>
              </HStack>
            </Th>
            <SortableTableHeader
              name={t`Age`}
              isSortActive={sortMethod === 'new'}
              isSortDescending={isSortDescending}
              isNumeric
              onClick={() => onHeaderClick('new')}
            />
            {graduated ? <Th isNumeric>Graduated</Th> : null}
            <Th isNumeric>Txns</Th>
            {graduated ? (
              <Th isNumeric>Volume</Th>
            ) : (
              <SortableTableHeader
                name={t`Volume`}
                isSortActive={sortMethod === 'volume'}
                isSortDescending={isSortDescending}
                isNumeric
                onClick={() => onHeaderClick('volume')}
              />
            )}
            <Th isNumeric>5M</Th>
            <Th isNumeric>1H</Th>
            <Th isNumeric>6H</Th>
            <Th isNumeric>24H</Th>
            <Th isNumeric>Staking APR</Th>
            {graduated ? (
              <Th isNumeric>Liqudity</Th>
            ) : (
              <SortableTableHeader
                name={t`Liquidity`}
                isSortActive={sortMethod === 'liquidity'}
                isSortDescending={isSortDescending}
                isNumeric
                onClick={() => onHeaderClick('liquidity')}
              />
            )}
            <SortableTableHeader
              name={t`Market Cap`}
              isSortActive={sortMethod === 'market_cap'}
              isSortDescending={isSortDescending}
              isNumeric
              onClick={() => onHeaderClick('market_cap')}
              textAlign="right"
            />
          </Tr>
        </Thead>

        <Tbody>
          {markets.map((market, i) => {
            const isMarketGraduated = market.graduatedAt !== undefined

            return (
              <LinkBox
                key={i}
                as={Tr}
                transform="scale(1)"
                cursor="pointer"
                role="group"
                _hover={{ bg: 'bgSecondary' }}
                transition="all 0.2s"
              >
                <Td>
                  <LinkOverlay
                    as={NavLink}
                    aria-label={`Link to token page for ${market.baseToken.symbol}`}
                    to={`/${market.chain}/${market.marketAddress}`}
                  />

                  <HStack>
                    <ChainIcon chain={market.chain} size="20px" />
                    <Text textColor="textSecondary">
                      {market.quoteToken.symbol}
                    </Text>
                    <Text textColor="textSecondary">/</Text>
                    <Image
                      src={market.logoUrl}
                      boxSize={6}
                      objectFit="contain"
                      ml={2}
                    />
                    <Text>{market.baseToken.symbol}</Text>
                    {isMarketGraduated ? (
                      <Box zIndex={1}>
                        <TouchFriendlyTooltip label="Tokens are graduated when their market cap exceeds $50k">
                          <CheckCircleIcon color="yellowBar" />
                        </TouchFriendlyTooltip>
                      </Box>
                    ) : null}
                  </HStack>
                </Td>
                <Td>
                  <HStack justify="flex-end">
                    {priceDisplay === 'usd' ? (
                      <Text>
                        {formattedNum(market.baseToken.priceUsd, {
                          places: 5,
                          usd: true
                        })}
                      </Text>
                    ) : (
                      <Text>
                        {formattedNum(market.baseToken.priceNative, {
                          allowSmallDecimals: true
                        })}{' '}
                        {market.quoteToken.symbol}
                      </Text>
                    )}
                  </HStack>
                </Td>
                <Td textAlign="right">
                  {formatShortDistanceToNow(market.createdAt)}
                </Td>
                {graduated ? (
                  <Td textAlign="right">
                    {market.graduatedAt
                      ? formatShortDistanceToNow(market.graduatedAt)
                      : '--'}
                  </Td>
                ) : null}
                <Td>
                  <HStack justifyContent="flex-end">
                    <Text textColor="green.400">{market.numberBuys}</Text>
                    <Text textColor="textSecondary">/</Text>
                    <Text textColor="red.500">{market.numberSells}</Text>
                  </HStack>
                </Td>
                <Td textAlign="right">
                  {formattedNum(market.volumeUsd, {
                    places: 0,
                    usd: true
                  })}
                </Td>
                <Td textAlign="right">
                  <PriceChangeColumn value={market.baseTokenPctChange5m} />
                </Td>
                <Td textAlign="right">
                  <PriceChangeColumn value={market.baseTokenPctChange1h} />
                </Td>
                <Td textAlign="right">
                  <PriceChangeColumn value={market.baseTokenPctChange6h} />
                </Td>
                <Td textAlign="right">
                  <PriceChangeColumn value={market.baseTokenPctChange1d} />
                </Td>
                <Td textAlign="right">
                  {formattedNum(market.stakingApr * 100, {
                    places: 2
                  })}
                  %
                </Td>
                <Td textAlign="right">
                  {formattedNum(market.liquidityUsd, {
                    places: 0,
                    usd: true
                  })}
                </Td>
                <Td textAlign="right">
                  {formattedNum(market.circulatingSupplyUsd, {
                    places: 0,
                    usd: true
                  })}
                </Td>
              </LinkBox>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default MarketsTable
