import { useInfiniteQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketData, TMMarketQueryParam } from 'types/dexbarn'
import { Market } from 'types/market'
import { getResizedImageUrl } from 'utils/image'

export interface UseGetMarketsParams {
  chain: Chain
  enabled?: boolean
  excludeLowVolumeMarkets?: boolean
  filterBy?: TMMarketQueryParam.FilterBy
  graduated?: boolean
  isDesc?: boolean
  orderBy?: TMMarketQueryParam.OrderBy
  pageSize?: number
}

const useGetMarketsFromBarn = ({
  chain,
  enabled,
  excludeLowVolumeMarkets,
  filterBy,
  graduated,
  isDesc,
  orderBy = 'new',
  pageSize = 20
}: UseGetMarketsParams) => {
  const fetchMarkets = useDexbarnGet<MarketData[]>(`/v1/markets/${chain}`)
  const fetchGraduatedMarkets = useDexbarnGet<MarketData[]>(
    `/v1/markets/${chain}/graduated`
  )

  return useInfiniteQuery<Market[]>({
    enabled,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      let markets: MarketData[] = []
      if (graduated) {
        markets = await fetchGraduatedMarkets({
          params: {
            isDesc,
            orderBy,
            pageNum: pageParam,
            pageSize
          }
        })
      } else {
        markets = await fetchMarkets({
          params: {
            excludeLowVolumeMarkets,
            filterBy,
            isDesc,
            orderBy,
            pageNum: pageParam,
            pageSize
          }
        })
      }

      return markets.map((market) => {
        return {
          baseToken: market.baseToken,
          baseTokenPctChange1d: market.baseTokenPctChange1d,
          baseTokenPctChange1h: market.baseTokenPctChange1h,
          baseTokenPctChange5m: market.baseTokenPctChange5m,
          baseTokenPctChange6h: market.baseTokenPctChange6h,
          chain,
          circulatingSupply: market.circulatingSupply,
          circulatingSupplyUsd: market.baseMarketCapUsd,
          createdAt: new Date(market.marketCreatedAt),
          description: market.description,
          graduatedAt: market.graduatedTimestamp
            ? new Date(market.graduatedTimestamp)
            : undefined,
          liquidityUsd: market.quoteLiquidityUsd,
          logoUrl: getResizedImageUrl(market.iconUrl) || '',
          marketAddress: market.marketAddress,
          numberBuys: market.numberBids,
          numberSells: market.numberAsks,
          quoteToken: {
            address: market.quoteToken.address,
            decimals: market.quoteToken.decimals,
            name: market.quoteToken.name,
            priceNative: market.quoteToken.priceNative,
            priceUsd: market.quoteToken.priceUsd,
            symbol: market.quoteToken.symbol
          },
          stakingApr: market.stakingApr,
          stakingPct: market.stakingPct,
          vestingPct: market.vestingPct,
          volumeUsd: market.volumeUsd
        }
      })
    },
    queryKey: [
      'GetMarkets',
      pageSize,
      orderBy,
      filterBy,
      excludeLowVolumeMarkets,
      isDesc,
      chain
    ]
  })
}

export default useGetMarketsFromBarn
