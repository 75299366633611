import { Box, HStack, Link, Spacer, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Box
      width="full"
      bgColor="bgPrimary"
      borderTop="1px solid"
      borderTopColor="border"
      pb={{ base: '56px', md: 0 }}
    >
      <HStack
        maxW="1600px"
        margin="0 auto"
        px={{ base: 4, md: 6 }}
        py={{ base: 4, md: 8 }}
        textColor="textSecondary"
        flexWrap="wrap"
      >
        <HStack fontSize="sm" spacing={4}>
          <Link
            isExternal
            aria-label="Token Mill X Account"
            href="https://x.com/token_mill"
          >
            <Trans>/X</Trans>
          </Link>

          <Link
            isExternal
            aria-label="Token Mill Privacy Policy"
            href={`${window.location.origin}/privacy`}
          >
            <Trans>/Privacy</Trans>
          </Link>

          <Link
            isExternal
            aria-label="Token Mill Terms of Service"
            href={`${window.location.origin}/tos`}
          >
            <Trans>/Terms of Service</Trans>
          </Link>

          <Link
            isExternal
            aria-label="Token Mill Docs"
            href="https://docs.tokenmill.xyz/"
          >
            <Trans>/Docs</Trans>
          </Link>

          <Link
            isExternal
            aria-label="Token Mill Telegram"
            href="https://t.me/+pB0uP3RLIihhODA0"
          >
            <Trans>/Telegram</Trans>
          </Link>
        </HStack>

        <Spacer />

        <Text fontSize="sm" textColor="textSecondary">
          © {year} Joemart Ltd
        </Text>
      </HStack>
    </Box>
  )
}

export default Footer
